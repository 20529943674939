import React, { useState } from 'react';
import Nikewishlist from './resolvers/nikewishlist';
import ReactDOM from 'react-dom';
import NikeApp from './resolvers/nikeapp';
const SetCookie = () => {
  const [token, setToken] = useState('')
  function myChangeHandler(event) {
    setToken(event.target.value)
  }

  function mySubmitHandler(event) {
    event.preventDefault();
    localStorage.setItem('Authorization', `Bearer ${token}`);
    window.location.href = '/'
  }
  return (
    <>
      <h1>CheekyBricki Wishlist Setup</h1>
      <a href="https://unite.nike.com/auth/unite_session_cookies/v1">ACESS TOKEN - MUSISZ BYĆ ZALOGOWANY / ODŚWIEŻYĆ STRONĘ NIKE</a>
      <p>TOKEN RESETUJE SIĘ CO GODZINĘ</p>
      <textarea
        type='text'
        placeholder="access_token:"
        size='60'
        style={{
          height: '400px',
          width: '500px',
          resize: 'none'
        }}
        onChange={myChangeHandler}
      />
      <br />
      <button
        style={{
          height: '30px',
          width: '90px'
        }}
        type='submit'
        onClick={mySubmitHandler}
      >PRZEŚLIJ</button>
      <p>by cwibel#0001</p>
    </>

  );
}



const App = () => {
  function resolve() {
    const searchParams = new URLSearchParams(window.location.search);
    const provider = searchParams.get('provider');

    if (searchParams.get('done')) {
      return <div>Nie udało się dodać do wishlisty</div>
    }

    switch (provider) {
      case 'nikewishlist':
        return <Nikewishlist url={`https://api.nike.com/buy/list_items/v1/`} />
      case 'nikeapp':
        return <NikeApp />
      default:
        return ReactDOM.render(<SetCookie />, document.getElementById('root'));
    }
  }

  return resolve()
}

export default App