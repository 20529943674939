import React, {
    useState,
    useEffect,
    useRef
} from 'react';

const Home = ({
        url
    }) => {
        const [error, setError] = useState([]);
        const inputEl = useRef(null);

        function uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0,
                    v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }


        useEffect(() => {
            const searchParams = new URLSearchParams(window.location.search);
            if (searchParams.get('done')) {
                setError(['Nie udało się dodać do ulubionych']);
                return;
            }

            const requiredParams = ['pid'];
            const params = {
                pid: searchParams.get('pid'),
            }

            const currentErrors = requiredParams.map(val => params[val] ? null : `Brakuje parametru ${val}`).filter(val => val);
            if (currentErrors.length) {
                setError(currentErrors)
                return
            }

            window.history.pushState({}, null, window.location.toString() + '&done=cb');

            var myHeaders = new Headers();
            myHeaders.append("Authorization", localStorage.getItem('Authorization'));
            myHeaders.append("Content-Type", "text/plain");
            var getWishlist = {
                method: 'GET',
                headers: myHeaders,


            };

            fetch('https://api.nike.com/buy/lists/v1?filter=isDefault(true)&filter=country(pl)', getWishlist)
                .then(response => {
                    if (!response.ok){
                        window.location.href = '/'
                        return
                    }
                    return response.json()
                })
                .then(data => {
                    var wishlistidentifier = data.objects[0].id
                    var raw = JSON.stringify({
                        "id": "",
                        "productId": `${params.pid}`,
                        "skuId": null,
                        "wishlistId": `${wishlistidentifier}`
                    })
                    var requestOptions = {
                        method: 'PUT',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                    };



                    return fetch(`https://api.nike.com/buy/list_items/v1/${uuidv4()}`, requestOptions)

                })
                .then(response => {
                    if (!response.ok){
                        window.location.href = '/'
                        return
                    }
                    window.location.href = 'https://www.nike.com/pl/cart';
                })
                .catch(error => console.log('error', error));

        }, [url]);



        if (error.length) {
            return (
                <div>
                    {error.map((val, idx) => <div key={idx}>{val}</div>)}
                </div>
            )
        }
    
        return (
            <>
                <form id='test' ref={inputEl} style={{ display: 'none' }} />
                <div>Trwa dodawanie do wishlisty..</div>
            </>
        )
    }
    
    export default Home