import React, {
    useState,
    useEffect,
    useRef
} from 'react';
import { isMobile } from 'react-device-detect';

const Home = (() => {
    const [error, setError] = useState([]);
    const inputEl = useRef(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get('done')) {
            setError(['Nie udało się otworzyć w aplikacji..']);
            return;
        }

        const requiredParams = ['sku'];
        const params = {
            sku: searchParams.get('sku'),
        }

        const currentErrors = requiredParams.map(val => params[val] ? null : `Brakuje parametru ${val}`).filter(val => val);
        if (currentErrors.length) {
            setError(currentErrors)
            return
        }
        window.history.pushState({}, null, window.location.toString() + '&done=cb');

        if (isMobile) {
            window.location.href = `mynike://x-callback-url/product-details?style-color=${params.sku}&redirect=true`;
        }
        else {
            window.location.href = `https://www.nike.com/pl/t/CheekyBricki/${params.sku}`;
        }

    }, []);

    if (error.length) {
        return (
            <div>
                {error.map((val, idx) => <div key={idx}>{val}</div>)}
            </div>
        )
    }
    return (
        <>
            <form id='test' ref={inputEl} style={{ display: 'none' }} />
            <div>Trwa otwieranie..</div>
        </>
    )
})

export default Home